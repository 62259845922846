<template>
  <v-container fluid class="tb-layout-browse1">
    <v-card class="pa-2">
      <BreadHeader :title="model.name.singular"> </BreadHeader>

      <AError :api="apiGenerateReport" />
      <AError :api="apiDownloadFinanceReport" />

      <v-card>
        <v-tabs v-model="tab" background-color="#063058" dark>
          <v-tab :key="1" :href="`#tab-1`">
            Duration Analysis Report &nbsp;
            <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>
          </v-tab>
          <v-tab :key="2" :href="`#tab-2`">
            Generate Report &nbsp;
            <v-icon>mdi-note-search</v-icon>
          </v-tab>
          <v-tab :key="3" :href="`#tab-3`">
            Finance Report &nbsp;
            <v-icon>mdi-file-chart</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" :value="`tab-1`">
            <v-card flat>
              <duration-analysis-report
                :params="params"
              ></duration-analysis-report>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2" :value="`tab-2`">
            <v-card flat>
              <generate-report
                :generateReportData="generateReportData"
                :generateReportError="generateReportError"
                :showGenerateReport="showGenerateReport"
                :notReadyReportData="notReadyReportData"
                @generateReport="generateReport"
                @downloadReport="downloadReport"
              ></generate-report>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="3" :value="`tab-3`">
            <v-card flat>
              <v-card-text>
                <finance-report
                  :financeReportData="financeReportData"
                  :financeReportError="financeReportError"
                  @generateFinanceReport="generateFinanceReport"
                >
                </finance-report>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <HrdcLoader
        :loadingDialog="
          apiGenerateReport.isLoading || apiDownloadReport.isLoading
        "
      ></HrdcLoader>
    </v-card>
  </v-container>
</template>
<script>
import * as XLSX from "xlsx";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import FinanceReport from "./FinanceReport";
import GenerateReport from "./GenerateReport";
import hrdcFunction from "@/objects/hrdcFunction";
import DurationAnalysisReport from "./DurationAnalysisReport";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";

export default {
  mixins: [hrdcFunction],
  components: {
    DurationAnalysisReport,
    GenerateReport,
    FinanceReport,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    hrdc_tpdiy: (state) => state.hrdc_tpdiy.data,
  }),
  props: ["params"],
  data: () => ({
    api: new Api(),
    apiGenerateReport: new Api(),
    apiDownloadReport: new Api(),
    apiDownloadFinanceReport: new Api(),
    conditions: [],
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    listAllViews: [],
    dataStatus: "active",
    columns: [],
    paginationSize: 50,
    paginationSizeSelector: [50, 100, 150, 200],
    modelKey: "",
    parentId: "",
    cColumnSort: [],
    displayRowCount: 10,
    selectedCustomColums: [],
    selectedAdvanceFilterConditions: [],
    selectedFilterConditions: [],
    switchToAdvanceFilter: false,
    isShow: false,
    allResizeColumns: [],
    columnSelectionDisabledCount: 2,
    currentPageNo: 1,
    tab: "tab-1",
    loadingDialog: false,
    generateReportData: {
      searchRptDateRange: [],
      week: null,
      generalInfo: {
        newApplications: null,
        resendApplications: null,
        grantsApproved: null,
        claimsSubmitted: null,
        claimsApproved: null,
        transFrHrdcToYyc: null,
      },
    },
    generateReportError: {
      dateRange: null,
    },
    showGenerateReport: false,
    notReadyReportData: ["pendingGrantsWithIssue"],
    financeReportData: {
      searchRptDateRange: [],
      cutOffBy: null,
    },
    financeReportError: {
      dateRange: null,
      cutOffBy: null,
    },
    financeReportHeaders: [
      "Company Name",
      "Price",
      "Invoice No.",
      "Payment Received Date",
      "Enrollment Date",
    ],
  }),
  watch: {
    //
  },
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.modelKey = this.$route.params.modelKey;
    this.parentId = this.$route.params.parentId;

    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGenerateReport: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/report/generate-report`,
        method: "post",
      },
      apiDownloadReport: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/report/download-report`,
        method: "post",
      },
      apiDownloadFinanceReport: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/report/download-finance-report`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    // Perform first check on user role, before enter (to prevent salesperson enter to unwanted view).
    this.roleMiddleware(this.auth.roles, this.service.key);
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.financeReportError) {
        this.financeReportError[error] = null;
      }
    },
    updateEditedCellData(cell) {
      let editedRecordField = cell.getField();
      let editedRecordValue = cell.getValue();
      let rowData = cell.getData();
      const { applicationUuid, stageId } = rowData;
      const stagePriorNeedToBeExceed = this.hrdcData.stageIndex[stageId];

      let initialValue = null;
      let editMode = false;
      let originalInitialValue = cell.getInitialValue();
      if (originalInitialValue) {
        initialValue = this.$moment(new Date(originalInitialValue)).format(
          "YYYY-MM-DD"
        );
        editMode = true;
      }

      let columnDataType = cell.getColumn().getDefinition().dataType;

      if (columnDataType == "date") {
        if (editedRecordValue) {
          editedRecordValue = new Date(editedRecordValue);
        }
      }

      if (columnDataType == "dateWithBgColor") {
        if (editedRecordValue) {
          editedRecordValue = new Date(editedRecordValue);
        }
      }

      // Set api url based on action.
      const data = this.getUpdateStageData(editedRecordField);

      // Check is this taxpod admin (only taxpod admin allow to edit dates).
      if (!this.isTaxpodAdminRoles) {
        this.$store.dispatch("showMessage", {
          message: "Only taxPOD Admin allow to edit.",
          messageType: "error",
          timeout: 2000,
        });
        cell.setValue(null, true);
        return;
      }

      // Check stage priority.
      if (
        data.stageToExceed > stagePriorNeedToBeExceed ||
        stagePriorNeedToBeExceed == undefined
      ) {
        this.$store.dispatch("showMessage", {
          message: "Please complete previous step to update this date",
          messageType: "error",
          timeout: 2000,
        });
        cell.setValue(null, true);
        return;
      }

      // Only update if value is not empty.
      if (editedRecordValue) {
        this.api.setMethod("POST");
        let params = {
          applicationUuid,
          referById: this.auth.uuid,
          editMode,
        };

        params[data.dateTerm] = editedRecordValue;

        this.api.setParams(params);
        this.api.setUrl(
          `${this.$service[this.service.key]}/v1/en/console/${data.url}`
        );

        this.api.setCallbackCompleted(() => {
          this.$store.dispatch("showMessage", {
            message: "Updated successfully.",
            messageType: "success",
            timeout: 2000,
          });

          // Set new stageId.
          cell.getRow().getData().stageId = data.nextStageId;

          // Set refund email updated date +14 days.
          if (editedRecordField == "refundEmailArToAp") {
            cell
              .getRow()
              .getCell("refundEmailUpdated")
              .setValue(
                editedRecordValue.setDate(editedRecordValue.getDate() + 14)
              );
          }
        });

        this.api.fetch();
      } else {
        cell.setValue(initialValue, true);
      }
    },
    generateReport(reportData) {
      this.apiGenerateReport.setParams({
        dateRange: reportData.searchRptDateRange,
      });

      this.apiGenerateReport.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (!status) {
            Object.keys(data).forEach((key) => {
              // Dynamically assign error messages based on object keys
              this.generateReportError[key] = data[key];
            });
          }

          if (status) {
            this.generateReportData = {
              searchRptDateRange: reportData.searchRptDateRange,
              week: data.week.toUpperCase(),
              weekStartDate: data.weekStartDate,
              weekEndDate: data.weekEndDate,
              generalInfo: {
                newApplications: data.newApplications,
                resendApplications: data.resendApplications,
                grantsApproved: data.grantsApproved,
                claimsSubmitted: data.claimsSubmitted,
                claimsApproved: data.claimsApproved,
                transFrHrdcToYyc: null,
              },
              ongoingApplications: {
                pendingGrantsApplicationOrApproval:
                  data.pendingGrantsApplicationOrApproval,
                grantsApprovedPendingForm: data.grantsApprovedPendingForm,
                pendingGrantsWithIssue: data.pendingGrantsWithIssue, // TODO
                pendingSubscriptionEndToReqInvoice:
                  data.pendingSubscriptionEndToReqInvoice,
                pendingReqOfHrdcInvoice: 0,
                pendingHrdcInvoiceToSubmitClaim:
                  data.pendingHrdcInvoiceToSubmitClaim,
                pendingSubscriptionEndToSubmitClaim:
                  data.pendingSubscriptionEndToSubmitClaim,
                pendingClaimSubmission: data.pendingClaimSubmission,
                claimsSubmitted: data.onGoingClaimsSubmitted,
                claimsApproved: data.onGoingClaimsApproved,
              },
              pendingRefunds: {
                pendingCnReq: data.pendingRefundCnReq,
                pendingCnApproval: data.pendingGenerateCn,
                pendingEmailSubmissionForRefund:
                  data.pendingEmailSubmissionForRefund,
                pendingEmailApprovalForRefund:
                  data.pendingEmailApprovalForRefund,
                pendingRefund: data.pendingRefundFinance,
                failedRefund: data.pendingRefundFailed,
                pendingEmailPAtoClient: data.pendingRefundEmailPAtoClient,
              },
              pendingAccountEnrollment: data.pendingAccountEnroll,
            };

            this.showGenerateReport = true;
          }
        } catch (err) {
          console.log(err);
        }
      });

      this.apiGenerateReport.fetch();
    },
    async downloadReport(reportData, sumData) {
      await this.$axios
        .get(this.apiDownloadReport.url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.state.auth.data.token}`,
          },
          params: {
            reportData,
            sumData,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Report.docx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if ((error.response.status == 404) | true) {
            this.$store.dispatch("showMessage", {
              message:
                "Failed to Preview/Download attachment, kindly contact administrator",
              messageType: "error",
              timeout: 2000,
            });
          }
        });
    },
    async generateFinanceReport(financeReportData) {
      this.loadingDialog = !this.loadingDialog;

      this.apiDownloadFinanceReport.setParams({
        cutOffBy: financeReportData.cutOffBy,
        dateRange: financeReportData.searchRptDateRange,
      });
      this.apiDownloadFinanceReport.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();

          const { status, data } = response;
          if (status == "NO_DATA_FOUND") {
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "info",
              timeout: 2000,
            });
          }

          if (!status) {
            Object.keys(data).forEach((key) => {
              // Dynamically assign error messages based on object keys
              this.financeReportError[key] = data[key];
            });
          }

          if (status === true) {
            let worksheetData = [];
            for (const item of data) {
              worksheetData.push({
                companyName: item.oldCompanyName,
                price: "RM " + item.totalPrice.toLocaleString(),
                hrdcInvoiceNo: item.manualInvoiceToHrdc,
                paymentReceivedDate: item.paymentReceivedDate
                  ? this.$moment(item.paymentReceivedDate).format("YYYY-MM-DD")
                  : null,
                enrollmentDate: item.enrollmentDate
                  ? this.$moment(item.enrollmentDate).format("YYYY-MM-DD")
                  : null,
              });
            }

            /* generate worksheet and workbook */
            const worksheet = XLSX.utils.json_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              "Finance Claim Basis"
            );

            /* fix headers */
            XLSX.utils.sheet_add_aoa(worksheet, [this.financeReportHeaders], {
              origin: "A1",
            });

            /* calculate column width */
            var wscols = [
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];

            worksheet["!cols"] = wscols;

            XLSX.writeFile(workbook, `Finance Claim Basis.xlsx`);
          }

          this.loadingDialog = !this.loadingDialog;
        } catch (err) {
          console.log(err);
        }
      });

      this.apiDownloadFinanceReport.fetch();
    },
  },
};
</script>
